import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

// Components
import { PersonOutline as PersonIcon } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";

// Action
import { logout } from "actions/userActions";

// Selectors
import { selectUser, showLoginForm } from "actions/userActions";

// Utils
import { getCurrencySymbolFormCurrencyId } from "utils/currency";

interface TopBarUserProps {
  className?: string;
}

const StyledPersonIcon = styled(PersonIcon)`
  margin-right: 0.5rem;
  width: 3rem;
  height: 3rem;
`;

const StyledUserIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserName = styled.span`
  font-size: 2rem;
  margin-right: 0.8rem;
  font-weight: bolder;
`;

const StyledBalance = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  color: #999999;
  margin-bottom: 0.4rem;
`;

const StyledDivider = styled.div`
  width: 1px;
  background-color: white;
  margin: 0 2rem;
  height: 3rem;
`;

const StyledLogInLink = styled(Typography)`
  text-transform: uppercase;
  font-size: 2.4rem;
  cursor: pointer;
`;

const StyledBalanceWrapper = styled(Grid)`
  width: auto;
`;

const TopBarUser = ({ className = "" }: TopBarUserProps) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const isUserLogedIn = useSelector(selectUser);

  const handleLoginClick = () => () => {
    dispatch(showLoginForm());
  };

  return (
    <Grid className={className}>
      {!isUserLogedIn && (
        <StyledLogInLink onClick={handleLoginClick()}>
          <FormattedMessage id="General.login" defaultMessage="Log in" />
        </StyledLogInLink>
      )}

      {user && (
        <>
          <StyledUserIconWrapper>
            <StyledPersonIcon />
            <StyledUserName> {user.username}</StyledUserName>

            <StyledDivider />

            <StyledBalanceWrapper container direction="column">
              <StyledBalance>
                <FormattedMessage
                  id="General.balance"
                  defaultMessage="Balance"
                />
              </StyledBalance>
              <span>
                {user.availableBalance}
                {getCurrencySymbolFormCurrencyId(user.currencyId)}
              </span>
            </StyledBalanceWrapper>

            <StyledDivider />

            <StyledLogInLink onClick={() => dispatch(logout())}>
              <FormattedMessage id="General.logout" defaultMessage="Log out" />
            </StyledLogInLink>
          </StyledUserIconWrapper>
        </>
      )}
    </Grid>
  );
};

export default TopBarUser;
