type availableCurrecies = "EUR" | "USD";

export const getCurrencySymbolFormCurrencyId = (
  currencyId: availableCurrecies
) => {
  switch (currencyId) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return "€";
  }
};
