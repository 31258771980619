import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

// Component
import UserDetail from "components/userBar/UserDetail";

// Actions
import { selectUser } from "actions/userActions";

import providerUserBarScreenshot from "images/provider-user-bar.png";

const StyledUserBar = styled.div`
  width: 30rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: #e6e6e6;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;

const StyledScreenshot = styled.img`
  width: 100%;
  height: ${window.innerHeight - 150}px;
`;

const UserBar = () => {
  const user = useSelector(selectUser);

  if (!user) {
    return null;
  }

  return (
    <StyledUserBar>
      <UserDetail user={user} />
      <StyledScreenshot src={providerUserBarScreenshot} />
    </StyledUserBar>
  );
};

export default UserBar;
