export default {
  // General
  "General.menu": "menu",
  "General.login": "log in",
  "General.logout": "log out",
  "General.username": "user name",
  "General.password": "password",
  "General.balance": "Balance",
  "General.payout": "Payout",

  // Landing Page
  "LandingPage.chooseProvider": "Choose your provider"
};
