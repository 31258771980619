export default {
  // General
  "General.menu": "speisekarte",
  "General.login": "einloggen",
  "General.logout": "ausloggen",
  "General.username": "nutzername",
  "General.password": "passwort",
  "General.balance": "Balance",
  "General.payout": "Auszahlung",

  // Landing Page
  "LandingPage.chooseProvider": "Wählen Sie Ihren Provider"
};
