import { actionTypes as localizationActionsTypes } from "actions/localizationActions";

interface ILocalizationState {
  locale: string;
}

const defaultState = {
  locale: "en"
};

function localizationReducer(
  state: ILocalizationState = defaultState,
  { type, payload }: any
) {
  switch (type) {
    case localizationActionsTypes.CHANGE_LOCALE:
      return {
        ...state,
        locale: payload
      };

    default:
      return state;
  }
}

export default localizationReducer;
