import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

// Actions
import { changeLocale } from "actions/localizationActions";

import gbFlag from "images/gb-flag.png";
import deFlag from "images/de-flag.png";

const StyledLanguageList = styled.ul`
  list-style: none;
  display: flex;
`;

const StyledLanguage = styled.li`
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  color: black;
  align-items: center;
`;

const StyledFlag = styled.img`
  margin-bottom: 0.8rem;
  width: 5rem;
  height: 3.2rem;
`;

const StyledFlagText = styled.span<{ labelColor: string }>`
  color: ${({ labelColor }) => labelColor};
`;

interface ILanguageSelectorProps {
  labelColor: string;
}

const LanguageSelector = ({ labelColor }: ILanguageSelectorProps) => {
  const dispatch = useDispatch();

  return (
    <StyledLanguageList>
      <StyledLanguage onClick={() => dispatch(changeLocale("en"))}>
        <StyledFlag src={gbFlag} />
        <StyledFlagText labelColor={labelColor}>English</StyledFlagText>
      </StyledLanguage>
      <StyledLanguage onClick={() => dispatch(changeLocale("de"))}>
        <StyledFlag src={deFlag} />
        <StyledFlagText labelColor={labelColor}>Deutsch</StyledFlagText>
      </StyledLanguage>
    </StyledLanguageList>
  );
};

export default LanguageSelector;
