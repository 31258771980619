import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";

export default createGlobalStyle`
  ${styledNormalize}
  
  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #111111;
    font-size: 1.6rem;
  }
  
  a {
    text-decoration: none;
  }

 
  .MuiFormLabel-root, .MuiTextField-root, .MuiInputBase-root {
    font-size: 1.6rem;
  }

  .MuiButton-label {
    font-size: 1.4rem;
  }

  .MuiTextField-root {
    width: 100%;
  }
  `;
