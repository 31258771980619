import { combineReducers } from "redux";

import { actionTypes as userActionsTypes } from "actions/userActions";

type accountType = "BONUS" | "MAIN";
type currency = "EUR";
type currencySymbol = "€";

type Balance = {
  accountType: accountType;
  balance: number;
  currencyId: currency;
  currencySymbol: currencySymbol;
};

type User = {
  availableBalance: number;
  currencyId: string;
  firstName: string;
  id: string;
  languageId: "en";
  lastName: string;
  username: string;
  balance: Balance[];
};

type Session = {
  expiration: number;
  id: string;
};

interface IResultState {
  user: User | null;
  session: Session | null;
  activeProviderId: string | null;
  showLoginForm: boolean;
}

const defaultState = {
  user: null,
  session: null,
  activeProviderId: null,
  showLoginForm: false
};

function result(state: IResultState = defaultState, { type, payload }: any) {
  switch (type) {
    case userActionsTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        showLoginForm: false
      };

    case userActionsTypes.LOGOUT:
      return defaultState;

    case userActionsTypes.SHOW_LOGIN_FORM:
      return {
        ...state,
        showLoginForm: true
      };

    case userActionsTypes.HIDE_LOGIN_FORM:
      return {
        ...state,
        showLoginForm: false
      };

    default:
      return state;
  }
}

function working(state: boolean = false, { type }: any) {
  switch (type) {
    case userActionsTypes.LOGIN:
      return true;

    case userActionsTypes.LOGIN_SUCCESS:
      return false;

    default:
      return false;
  }
}

function error(state = null, { type, payload }: any) {
  switch (type) {
    case userActionsTypes.LOGIN_ERROR:
      return payload;

    case userActionsTypes.LOGIN:
    case userActionsTypes.LOGIN_SUCCESS:
      return false;

    default:
      return state;
  }
}

export default combineReducers({ error, result, working });
