import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

// Components
import { Grid, Card, TextField, Button } from "@material-ui/core";

// Actions
import { login } from "actions/userActions";

const StyledLoginPage = styled.div`
  margin: auto;
  width: 40rem;

  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

const StyledCard = styled(Card)`
  padding: 3rem 5rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledLoginBtn = styled(Button)`
  width: 75px;
  margin-left: auto;
`;

const LoginForm = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <StyledLoginPage>
      <StyledCard>
        <StyledForm>
          <Grid>
            <TextField
              id="username"
              label={
                <FormattedMessage
                  id="General.username"
                  defaultMessage="User name"
                />
              }
              onChange={e => setUsername(e.target.value)}
              margin="normal"
            />
          </Grid>

          <Grid>
            <TextField
              id="password"
              label={
                <FormattedMessage
                  id="General.password"
                  defaultMessage="Password"
                />
              }
              onChange={e => setPassword(e.target.value)}
              margin="normal"
              type="password"
            />
          </Grid>

          <StyledLoginBtn
            value="Login"
            onClick={e => dispatch(login(username, password))}
            color="secondary"
          >
            <FormattedMessage id="General.login" defaultMessage="Login" />
          </StyledLoginBtn>
        </StyledForm>
      </StyledCard>
    </StyledLoginPage>
  );
};

export default LoginForm;
