import React from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Selectors
import { selectLocale } from "actions/localizationActions";
import { selectIsLoginFormShown } from "actions/userActions";

// Components

import Modal from "bricks/modal/Modal";
import { LoginForm } from "components/common";

// Other
import Routes from "./Routes";
import locales, { availableLocales } from "localization";

const StyledAppWrapper = styled.div<any>`
  width: 100%;
`;

const App = () => {
  const locale: availableLocales = useSelector(selectLocale);
  const isLoginFormShown: boolean = useSelector(selectIsLoginFormShown);

  return (
    <IntlProvider locale={locale} messages={locales[locale]}>
      <StyledAppWrapper>
        <Routes />
        {isLoginFormShown && (
          <Modal>
            <LoginForm />
          </Modal>
        )}
      </StyledAppWrapper>
    </IntlProvider>
  );
};

export default App;
