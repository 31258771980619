import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import { showLoginForm } from "actions/userActions";

// Selectors
import { selectUser, selectUserLoading } from "actions/userActions";

interface IAuthenticatedProps {
  children: JSX.Element;
  isLogged: boolean;
}

interface IPrivateRouteProps {
  component: any;
  exact?: boolean;
  path: string;
}

const Authenticated = ({ isLogged, children }: IAuthenticatedProps) =>
  isLogged ? children : null;

const PrivateRoute = ({
  component: Component,
  ...props
}: IPrivateRouteProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectUserLoading);
  const isLogged = user ? true : false;

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!user) {
    dispatch(showLoginForm());
    return null;
    // history.push("/");
  }

  return (
    <Route
      {...props}
      render={routeProps => (
        <React.Fragment>
          <Authenticated isLogged={isLogged}>
            <Component {...routeProps} {...props} />
          </Authenticated>
        </React.Fragment>
      )}
    />
  );
};

export default PrivateRoute;
