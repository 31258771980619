import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";

// Components
import { IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageSelector from "components/common/LanguageSelector";
import TopBarUser from "components/common/TopBarUser";

// Others
import logo from "images/playyit-logo.png";

const StyledAppBar = styled(Toolbar)`
  font-size: 2.4rem;
  background-color: #4b4b59;
  position: static;
  color: white;
  height: 15rem;
`;

const StyledLanguageSelector = styled(LanguageSelector)<{ labelColor: string }>`
  margin: 0 2.5rem;
  color: red;
`;

const StyledTopBarUser = styled(TopBarUser)`
  margin-left: auto;
  margin-right: 2%;
`;

const StyledLogo = styled.img`
  width: 350px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

const StyledMenuText = styled(Typography)`
  text-transform: uppercase;
  font-size: 2.4rem;
`;

const TopBar: React.FC = (props: any) => {
  const path: string = props.history.location.pathname;

  if (path.includes("/providers/")) {
    return null;
  }

  return (
    <StyledAppBar>
      <IconButton edge="start" color="inherit" aria-label="Menu">
        <MenuIcon />
      </IconButton>
      <StyledMenuText>
        <FormattedMessage id="General.menu" defaultMessage="Menu" />
      </StyledMenuText>

      <StyledLanguageSelector labelColor="#FFFFFF" />

      <StyledLogo src={logo} />

      <StyledTopBarUser />
    </StyledAppBar>
  );
};

export default withRouter(TopBar);
