import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// Components
import { Typography, IconButton, Toolbar } from "@material-ui/core";
import LanguageSelector from "components/common/LanguageSelector";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import MenuIcon from "@material-ui/icons/Menu";

// Actions
import { selectUser } from "actions/userActions";

// Others
import logo from "images/playyit-logo.png";
import delasportLogo from "images/delasport-logo.png";
import xtipLogo from "images/xtip-logo.png";
import interWettenLogo from "images/inter-wetten-logo.png";
import tipicoLogo from "images/tipico-logo.png";
import williamHillLogo from "images/william-hill-logo.png";
import bet365Logo from "images/bet365-logo.png";

const StyledAppBar = styled(Toolbar)`
  background-color: ${props => props.theme.defaultBackground};
  color: #111111;
  height: 15rem;
`;

const StyledProvidersList = styled.ul`
  display: flex;
  list-style: none;
  width: calc(100% - 81rem); /* left -> 500px , right 300px */
  margin: 0;
`;

const StyledProvider = styled.img`
  width: 93%;
  margin-right: 7%;
  justify-content: center;
  align-items: center;
`;

const StyledLanguageSelector = styled(LanguageSelector)<{ labelColor: string }>`
  margin: 0 2.5rem;
  color: red;
`;

const StyledLogo = styled.img`
  width: 17rem;
`;

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
};

const StyledHomeIcon = styled(HomeIcon)`
  width: 3rem;
  height: 3rem;
  color: #000000;
`;

const StyledMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.3rem 1.4rem;
  background-color: #6ab0f2;
  color: #ffffff;
  font-size: 2rem;
  text-transform: uppercase;
`;

const StyledProviderLink = styled(Link)`
  width: 100%;
`;

const providers = [
  {
    name: "Delasport",
    backgroundImage: delasportLogo,
    id: "2000"
  },
  {
    name: "CBC",
    backgroundImage: xtipLogo,
    id: "2001"
  },

  {
    name: "Bet 365",
    backgroundImage: bet365Logo,
    id: "1"
  },
  {
    name: "Inter Wetten",
    backgroundImage: interWettenLogo,
    id: "2"
  },
  {
    name: "Tipico",
    backgroundImage: tipicoLogo,
    id: "4"
  },
  {
    name: "Wiliam Hill",
    backgroundImage: williamHillLogo,
    id: "5"
  }
];

const TopBarProvider: React.FC = (props: any) => {
  const user = useSelector(selectUser);
  const path: string = props.history.location.pathname;

  if (!path.includes("/providers/")) {
    return null;
  }

  return (
    <StyledAppBar>
      <IconButton edge="start" color="inherit" aria-label="menu">
        <Link to="/">
          <StyledHomeIcon />
        </Link>
      </IconButton>

      <StyledMenuWrapper>
        <IconButton edge="start" color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>

        <Typography variant="h6">
          <FormattedMessage id="General.menu" defaultMessage="Menu" />
        </Typography>
      </StyledMenuWrapper>

      <StyledLogo src={logo} />
      <StyledLanguageSelector labelColor="#000000" />
      {path.includes("/providers/") && user && (
        <StyledProvidersList>
          {providers.map(provider => (
            <StyledProviderLink
              key={provider.name}
              to={`/providers/${provider.id}`}
            >
              <StyledProvider src={provider.backgroundImage} />
            </StyledProviderLink>
          ))}
        </StyledProvidersList>
      )}
    </StyledAppBar>
  );
};

export default withRouter(TopBarProvider);
