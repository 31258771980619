import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import delasportLogo from "images/delasport-logo.png";
import xtipLogo from "images/xtip-logo.png";
import interWettenLogo from "images/inter-wetten-logo.png";
import tipicoLogo from "images/tipico-logo.png";
import williamHillLogo from "images/william-hill-logo.png";
import bet365Logo from "images/bet365-logo.png";

import rectanglePlaceholder1 from "images/landing-page-placeholder-1.png";
import rectanglePlaceholder2 from "images/landing-page-placeholder-2.png";
import rectanglePlaceholder3 from "images/landing-page-placeholder-3.png";

// Selectors

const StyledLandingPage = styled.div`
  padding: 2rem;
  background-color: #4b4b59;
  min-height: ${(window.innerHeight - 150) / 10}rem; // 1
`;

const StyledContentWrapper = styled.div`
  max-width: 192rem;
  margin: auto;
`;

const StyledHeading = styled.h2`
  color: white;
  font-size: 4.5rem;
  padding: 2rem;
  text-align: center;
  margin-top: 0;
`;

const StyledProvidersList = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-bottom: 7.5rem;
  padding: 0;
`;

const StyledProviderLink = styled.li`
  width: 15.9%;
  display: flex;
`;

const StyledProviderImage = styled.img`
  width: 100%;
  border-radius: 0.4rem;
`;

const StyledRectanglePlaceholdersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledRectanglePlaceholder = styled.img`
  width: 32.6%;
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const providers = [
  {
    name: "Delasport",
    backgroundImage: delasportLogo,
    id: "2000"
  },
  {
    name: "CBC",
    backgroundImage: xtipLogo,
    id: "2001"
  },

  {
    name: "Bet 365",
    backgroundImage: bet365Logo,
    id: "1"
  },
  {
    name: "Inter Wetten",
    backgroundImage: interWettenLogo,
    id: "2"
  },
  {
    name: "Tipico",
    backgroundImage: tipicoLogo,
    id: "4"
  },
  {
    name: "Wiliam Hill",
    backgroundImage: williamHillLogo,
    id: "5"
  }
];

const LandingPage = () => {
  return (
    <StyledLandingPage>
      <StyledContentWrapper>
        <StyledHeading>
          <FormattedMessage
            id="LandingPage.chooseProvider"
            defaultMessage="Choose your provider"
          />
        </StyledHeading>

        <StyledProvidersList>
          {providers.map(provider => (
            <StyledProviderLink key={provider.name}>
              <StyledLink to={`/providers/${provider.id}`}>
                <StyledProviderImage src={provider.backgroundImage} />
              </StyledLink>
            </StyledProviderLink>
          ))}
        </StyledProvidersList>

        <StyledRectanglePlaceholdersWrapper>
          <StyledRectanglePlaceholder src={rectanglePlaceholder1} />
          <StyledRectanglePlaceholder src={rectanglePlaceholder2} />
          <StyledRectanglePlaceholder src={rectanglePlaceholder3} />
        </StyledRectanglePlaceholdersWrapper>
      </StyledContentWrapper>
    </StyledLandingPage>
  );
};

export default LandingPage;
