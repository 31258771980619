import axios from "axios";
import set from "lodash.set";
import get from "lodash.get";
import { takeLatest, put } from "redux-saga/effects";
import { history } from "Routes";

// Actions
import { loginSuccess, loginError } from "actions/userActions";
import { actionTypes as userActionTypes } from "actions/userActions";

function* loginSagaWatcher({ payload }: any) {
  const { username, password } = payload;

  try {
    const resp = yield axios.post(
      "http://playyit-test.danubesoft.com:8880/api/sessions",
      {
        ipAddress: "192.168.0.2",
        plainTextPassword: password,
        username
      }
    );
    const processedData = {
      ...resp.data
    };

    const providers = get(processedData, "user.extensions.Providers");

    if (providers) {
      set(
        processedData,
        "user.extensions.Providers",
        providers.map((provider: { config: string; name: string }) => ({
          ...provider,
          config: JSON.parse(provider.config)
        }))
      );
    }

    yield put(loginSuccess(processedData));
  } catch (e) {
    console.error(e);
    yield put(loginError(e));
  }
}

function* logoutSagaWatcher({ payload }: any) {
  try {
    history.push("/");
  } catch (e) {
    console.error(e);
    yield put(loginError(e));
  }
}

function* startFetchBetslipLoopWatcher({ payload }: any) {
  try {
    console.log("startFetchBetslipLoopWatcher");

    const resp = yield axios.post(
      "https://xlive.danubesoft.com/XLiveMobile/LiveClientProcessMessageHandler.ashx",
      "action=GetBetSlip&tz=Europe%2FBratislava",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*"
        }
      }
    );
    console.log(resp, "================================");
  } catch (e) {
    console.error(e);
    yield put(loginError(e));
  }
}

export default function* userSaga() {
  yield takeLatest(userActionTypes.LOGIN, loginSagaWatcher);
  yield takeLatest(userActionTypes.LOGOUT, logoutSagaWatcher);
  yield takeLatest(
    userActionTypes.START_FETCH_BETSLIP_LOOP,
    startFetchBetslipLoopWatcher
  );
}
