import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

// Components
import { Person as PersonIcon } from "@material-ui/icons";

// Actions
import { logout } from "actions/userActions";

// Utils
import { getCurrencySymbolFormCurrencyId } from "utils/currency";

const StyledPersonIcon = styled(PersonIcon)`
  margin-right: 0.5rem;
  width: 3rem;
  height: 3rem;
  color: #999999;
`;

const StyledUserDetail = styled.div`
  padding: 2rem;
  height: 15rem;
  box-sizing: border-box;
`;

const StyledUserName = styled.span`
  font-size: 2rem;
  margin-right: 0.8rem;
  font-weight: bolder;
  color: #184ba3;
`;

const StyledBalance = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  color: #999999;
  margin-bottom: 0.4rem;
  font-size: 1.1rem;
`;

const StyledBalanceWrapper = styled(Grid)`
  width: auto;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const StyledPayout = styled.div`
  padding: 1.2rem 2rem;
  border: 1px solid black;
  cursor: pointer;
  margin-left: auto;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: white;
  font-weight: bold;
`;

const StyledLogout = styled.span`
  margin-left: auto;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1.6rem;
  color: #999999;
`;

const UserDetail = ({ user }: any) => {
  const dispatch = useDispatch();

  return (
    <StyledUserDetail>
      <StyledIconWrapper>
        <StyledPersonIcon />
        <StyledUserName> {user.username}</StyledUserName>
        <StyledLogout onClick={() => dispatch(logout())}>
          <FormattedMessage id="General.logout" defaultMessage="Log Out" />
        </StyledLogout>
      </StyledIconWrapper>

      <Grid container>
        <StyledBalanceWrapper container direction="column">
          <StyledBalance>
            <FormattedMessage id="General.balance" defaultMessage="Balance" />
          </StyledBalance>
          <span>
            {user.availableBalance}
            {getCurrencySymbolFormCurrencyId(user.currencyId)}
          </span>
        </StyledBalanceWrapper>

        <StyledPayout>
          <FormattedMessage id="General.payout" defaultMessage="Payout" />
        </StyledPayout>
      </Grid>
    </StyledUserDetail>
  );
};

export default UserDetail;
