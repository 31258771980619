import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

// Components
import { TopBar } from "components/common";
import { TopBarProvider } from "components/common";
import PrivateRoure from "components/common/PrivateRoute";
import { LandingPage, ProvidersPage } from "pages";

export const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <TopBar />
      <TopBarProvider />

      <Switch>
        <Route exact path="/" component={LandingPage} />
        <PrivateRoure exact path="/providers/:id" component={ProvidersPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
