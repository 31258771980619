import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const StyledModalWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
`;

const modalRoot = document.getElementById("modal-root");

class Modal extends Component<any> {
  el = document.createElement("div");

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componendWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  render() {
    const { children } = this.props;

    return ReactDOM.createPortal(
      <StyledModalWrapper>{children}</StyledModalWrapper>,
      this.el
    );
  }
}

export default Modal;
