import { createSelector } from "reselect";
import get from "lodash.get";

export const actionTypes = {
  LOGIN: "auth/LOGIN",
  LOGIN_ERROR: "auth/LOGIN_ERROR",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",

  LOGOUT: "auth/LOGOUT",

  SHOW_LOGIN_FORM: "user/SHOW_LOGIN_FORM",
  HIDE_LOGIN_FORM: "user/HIDE_LOGIN_FORM",

  START_FETCH_BETSLIP_LOOP: "user/START_FETCH_BETSLIP_LOOP"
};

const getUser = (state: any) => state.user.result.user;
export const selectUser = createSelector(
  getUser,
  userState => userState
);

export const getProviders = createSelector(
  getUser,
  user => {
    if (user) {
      return get(user, "extensions.Providers", []);
    }

    return [];
  }
);

const getUserLoading = (state: any) => state.user.working;
export const selectUserLoading = createSelector(
  getUserLoading,
  uisUserLoading => uisUserLoading
);

export const login = (username: string, password: string) => ({
  type: actionTypes.LOGIN,
  payload: { username, password }
});

export const loginSuccess = (payload: any) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload
});

export const loginError = (payload: any) => ({
  type: actionTypes.LOGIN_ERROR,
  payload
});

export const logout = () => ({
  type: actionTypes.LOGOUT
});

export const selectIsLoginFormShown = (state: any) =>
  state.user.result.showLoginForm;

export const showLoginForm = () => ({
  type: actionTypes.SHOW_LOGIN_FORM
});

export const hideLoginForm = () => ({
  type: actionTypes.HIDE_LOGIN_FORM
});

export const startFetchBetSlipLoop = () => ({
  type: actionTypes.START_FETCH_BETSLIP_LOOP
});
