import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

// Components
import UserBar from "components/userBar/UserBar";

// Selectors
import { selectUser, startFetchBetSlipLoop } from "actions/userActions";

const StyledProvidersPage = styled.div`
  width: calc(100% - 30rem);
  height: ${window.innerHeight - 150}px;
  overflow: hidden;
`;

const StyledProviderWrapper = styled.div<any>`
  height: 100%;
  display: ${({ activeProviderId, id }) =>
    activeProviderId === id ? "block" : "none"};
`;

interface IProvidersProps {
  config: {
    url: string;
    id: string;
  };

  name: string;
}

const ProvidersPage = (props: any) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const activeProviderId = props.match.params.id;
  const providers = user.extensions.Providers;

  dispatch(startFetchBetSlipLoop());

  return (
    <StyledProvidersPage>
      <UserBar />
      {providers.map((provider: IProvidersProps) => {
        return (
          <StyledProviderWrapper
            key={provider.config.id}
            activeProviderId={activeProviderId}
            id={provider.config.id}
          >
            <iframe
              title={provider.name}
              width="100%"
              height="100%"
              src={provider.config.url}
              id={provider.config.id}
            />
          </StyledProviderWrapper>
        );
      })}
    </StyledProvidersPage>
  );
};

export default ProvidersPage;
